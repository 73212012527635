<!--
 * @Author: lbh
 * @Date: 2021-04-23 15:47:04
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-29 14:44:08
 * @Description: 飯糰頁
-->
<template>
  <div
    class="ricepon t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="ricepon"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="RICPEON APP"
        content="帶來全新體驗"
        :type="1"
        :wtype="window.type"
        :image="getImgPath('Ricepon_top_BG.jpg')"
        :bgImage="getImgPath('b5.jpg')"
      />
      <!-- 標題 -->
      <selfProducts
        :type="window.type"
        title="多功能APP集於一身"
      />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfProducts from "@/components/commo/products";
import util from "@/js/utils/util";
export default {
  name: "self-ricepon",
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfProducts
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0
      },
    }
  },
  mounted () {
    util.setTitle('飯糰 – 薑軟件有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
        this.window.backH = parseInt(w / 2.5);
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  },

}
</script>
<style lang="less" scoped>
</style>
